<div class="ff-logo-div">
    <img class="ff-logo" src="../../assets/images/ff-logo.png" alt="FreightFox">
</div>
<div *ngIf="isDataAvailable && !consentSubmitted && !consentExist" class="center-div">
    <img class="ws-logo" src="/assets/images/WhatsApp_Logo_1.png" alt="Whatsapp">
    <h1>Get instant notifications via WhatsApp!</h1>
    <br>
    <h3>
        Never miss any new business opportunity on FreightFox!<br>
        Sign up to our WhatsApp profile to get instant notifications!
    </h3>
    <div class="user-det">
        <div class="user-name">
            <p *ngIf="userName" class="user-det-txt">{{userName}}</p>
            <p class="user-det-txt">{{userNumber}}</p>
        </div>
    </div>
    <button (click)="callForConsent()" class="sub-btn">SUBSCRIBE</button>
</div>
<div *ngIf="consentExist" class="center-div">
    <img class="ws-logo" src="/assets/images/WhatsApp_Logo_1.png" alt="Whatsapp">
    <h3>
        Thanks a lot!<br>
        You are already opted-in to receive WhatsApp notifications from FreightFox.
    </h3>
    <div class="user-det">
        <div class="user-name">
            <p *ngIf="userName" class="user-det-txt">{{userName}}</p>
            <p class="user-det-txt">{{userNumber}}</p>
        </div>
    </div>
</div>
<div *ngIf="consentSubmitted" class="center-div">
    <img class="ws-logo" src="/assets/images/WhatsApp_Logo_1.png" alt="Whatsapp">
    <h3>
        Thanks a lot!<br>
        You have successfully opted-in to stay in touch with us on WhatsApp.<br>
        We're excited to have you!
    </h3>
</div>
<div *ngIf="!isDataAvailable" class="center-div">
    <h3 class="top-margin-20">
        The URL has been expired.
    </h3>
</div>