import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/internal/operators';
import { Observable, throwError } from "rxjs";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    onboarding_host:any = environment.onboarding_host;

    constructor(private http: HttpClient) { 
      console.log(this.onboarding_host);
    }

    private handleError(error: HttpErrorResponse) {
        return throwError(error.error);
    }

    getUserDetails(code:any): Observable<any> {
          let options = new HttpHeaders();
          options.append("Content-Type", "multipart/form-data");
          return this.http
            .get<any>(this.onboarding_host + `kremlin/api/v1/whatsapp-consent/${code}`, {
              headers: options
            })
            .pipe(
              retry(1),
              map(data => {
                let dataToCache = data;
                return dataToCache;
              }),
              catchError(this.handleError.bind(this))
            ) as Observable<any>;
    }

    submitUserConsent(code:any): Observable<any> {
          let options = new HttpHeaders();
          options.append("Content-Type", "multipart/form-data");
          return this.http
            .get<any>(this.onboarding_host + `kremlin/api/v1/opt-in/whatsapp-consent/${code}`, {
              headers: options
            })
            .pipe(
              retry(1),
              map(data => {
                let dataToCache = data;
                return dataToCache;
              }),
              catchError(this.handleError.bind(this))
            ) as Observable<any>;
    }

}