import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  uniCode: any;
  userName: any;
  userNumber: any;
  isDataAvailable: boolean = true;
  consentExist:boolean = false;
  consentSubmitted:boolean = false;

  constructor(
    private userService: UserService,
  ) { 
    let urlPath = window.location.pathname;
    this.uniCode = urlPath.split('/')[2];
  }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails(){
    this.userService.getUserDetails(this.uniCode)
    .pipe(
      catchError((error: any) => {
        this.isDataAvailable = false;
        return throwError(error);
      })
    )
    .subscribe(resp => {
      if(!resp.mobileNumber){
        this.isDataAvailable = false;
      }else{
        this.consentExist = resp.isConsented;
        this.userName = resp.name;
        this.userNumber = resp.mobileNumber;
      }
    });
  }

  callForConsent(){
    this.userService.submitUserConsent(this.uniCode)
    .pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    )
    .subscribe(resp => {
      if(resp.success){
        this.consentSubmitted = true;
      }
    });
  }

}
